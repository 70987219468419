$(function () {

    $(window).on('resize', function () {
        checkSize();
    });

    let body = document.body;
    let hamburger = $('[data-hamburger]');
    let menu = $('[data-menu]');

    function checkSize() {
        if ((window.innerWidth > sizes.M) && (hamburger.hasClass('is-active'))) {
            $(hamburger).removeClass('is-active');
            $(body).removeClass('fixed');
            $(menu).removeClass('open');
        }
    }

    function isElementInViewport(process) {
        let scrollElem = $(window);
        let viewportTop = scrollElem.scrollTop();
        let viewportBottom = viewportTop + scrollElem.height();
        let elemTop = Math.round(process.offset().top);
        let elemBottom = elemTop + process.height();
        return ((elemTop < viewportBottom) && (elemBottom > viewportTop));
    }

    function checkAnimation() {
        let process = document.querySelector('[data-process]')

        if(process) {
            let process = $('[data-process]');
            isElementInViewport(process);

            if (process.hasClass('go')) {
                return;
            } else if (isElementInViewport(process)) {
                process.addClass('go');
            }
        }
    }

    $(document).scroll(function(){
        checkAnimation();
    });

    hamburger.on('click', function () {
        if ($(this).hasClass('is-active')) {
            $(this).removeClass('is-active');
            $(body).removeClass('fixed');
            $(menu).removeClass('open');
        } else {
            $(this).addClass('is-active');
            $(body).addClass('fixed');
            $(menu).addClass('open');
        }
    })
});

//кнопка вверх
let t;
let button = document.querySelector('[data-button-up]');

if (button) {
    button.addEventListener('click', () => {
        document.querySelector('body').scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    })
}

