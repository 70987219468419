require('../scss/app.scss');
import sizes from "../scss/_settings/_settings.scss";
window.sizes = sizes;
window.noZensmooth = true;

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
require('../fonts/css/Lemon_Tuesday.css');
require('./main');
require('./index/index');
require('jquery-parallax.js');
